@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alice&display=swap");

body {
  /*margin: 0;*/
  /*font-family: 'Montserrat', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.alice-regular {
  font-family: "Alice", serif;
  font-weight: 400;
  font-style: normal;
}

.arco-input,
.arco-input-group,
.arco-input-inner-wrapper,
.arco-input-inner-wrapper-default,
.arco-select-view,
.arco-btn,
.arco-picker {
  --border-radius-small: 20px;
}

.arco-card {
  --border-radius-small: 8px;
}

.own-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px 0px,
    rgba(0, 0, 0, 0.3) 0px 0px 1px 0px;
}

.own-header {
  margin: 10px 0;
}

.own-header-logo img {
  padding: 8px 25px;
}

@media (max-width: 768px) {
  .own-header {
    margin: 0;
  }

  .own-header-logo img {
    padding: 0;
  }
}

.display-only-on-mobile {
  display: none;
}

.display-only-on-desktop {
  display: block;
}

@media (max-width: 768px) {
  .display-only-on-mobile {
    display: block;
  }

  .display-only-on-desktop {
    display: none;
  }
}

.arco-menu-selected-label {
  background-color: #000 !important;
  opacity: 0.5 !important;
  height: 1px !important;
  bottom: 2px !important;
}
